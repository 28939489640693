import { getChallengeSlugFromLocation } from '../Location/locationProviderPropsMap';
import { syncInstance } from '../../services/instance';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';
import { isAvailableForJoinImmediately } from '../../selectors/challenges';
import { getMockedChallenge } from '../main/getMockedChallenges';
import { Challenges } from '../../editor/types/Experiments';
import { ControllerFlowAPI, IHttpClient } from '@wix/yoshi-flow-editor';
import { request } from '../../services/request';

import {
  challenge,
  ChallengeServerlessData,
} from '../../api/challenges/challenge.api';
import {
  listChallenges,
  listSections,
  listSteps,
  getChallenge,
} from '@wix/ambassador-challenges-v1-challenge/http';
import {
  ChallengeSection,
  ChallengeStep,
  DurationUnit,
} from '@wix/ambassador-challenges-v1-challenge/types';

export interface IChallengeDataProps {
  isGroupsInstalled: boolean;
  isDescriptionShown: boolean;
  challengeData: ChallengeServerlessData & {
    isAvailableForJoinImmediately: boolean;
  };
  challengeSections?: ChallengeSection[];
  challengeSteps?: ChallengeStep[];
  requestChallengeSections?: Function;
  requestChallengeSteps?: Function;
}

export const shouldReportErrorMonitor = (err) => err?.httpStatus !== 403;

export async function handleUserLogin(flowAPI: ControllerFlowAPI) {
  flowAPI.controllerConfig.wixCodeApi.user.onLogin(async () => {
    const challengeId = getChallengeSlugFromLocation(flowAPI);

    let challengeResponse: ChallengeServerlessData = {
      challenge: null,
    };

    syncInstance(flowAPI);

    try {
      if (challengeId) {
        challengeResponse = await requestChallenge(challengeId, flowAPI);
      }
    } catch (error) {
      handleError({
        error,
        context: 'onUserLogin.getChallenge',
        preventErrorMonitorReport: !shouldReportErrorMonitor(error),
      });
    }

    flowAPI.controllerConfig.setProps({
      challengeData: {
        ...challengeResponse,
        ...{
          isAvailableForJoinImmediately: isAvailableForJoinImmediately(
            challengeResponse.challenge,
          ),
        },
      },
    });
  });
}

export async function requestChallenge(
  slugOrChallengeId: string,
  flowAPI: ControllerFlowAPI,
): Promise<ChallengeServerlessData> {
  const mockedChallenge = getMockedChallenge(slugOrChallengeId, flowAPI);

  if (mockedChallenge) {
    return { challenge: mockedChallenge, badgesData: { badges: [] } };
  }

  if (!slugOrChallengeId) {
    return;
  }

  const isServerlessChallengeExperimentEnabled = flowAPI.experiments.enabled(
    Challenges.enableServerlessChallenge,
  );

  if (isServerlessChallengeExperimentEnabled) {
    return requestChallengeServerless(flowAPI.httpClient, slugOrChallengeId);
  } else {
    return {
      challenge: await requestChallengeHttp(flowAPI, slugOrChallengeId),
    };
  }
}

export async function requestChallengeServerless(
  httpClient: IHttpClient,
  id: string,
): Promise<ChallengeServerlessData> {
  return (await httpClient.request(challenge(id)))?.data;
}

export async function requestChallengeHttp(flowAPI, id: string) {
  const findChallengeResponse = (
    await request(flowAPI, listChallenges, {
      slug: id,
    })
  )?.data;

  if (findChallengeResponse.totalCount === 1) {
    return findChallengeResponse?.memberChallenges?.[0]?.challenge;
  }

  // Fallback to getting challenge by challengeId just in case.
  return (
    (
      await request(flowAPI, getChallenge, {
        challengeId: id,
      })
    )?.data?.challenge || null
  );
}

export async function requestChallengeSections(
  slugOrChallengeId: string,
  flowAPI: ControllerFlowAPI,
): Promise<ChallengeSection[]> {
  const mockedChallenge = getMockedChallenge(slugOrChallengeId, flowAPI);

  if (!slugOrChallengeId) {
    return null;
  } else if (mockedChallenge) {
    return [];
  }

  return (
    await request(flowAPI, listSections, {
      challengeId: slugOrChallengeId,
    })
  )?.data?.sections;
}

export async function requestChallengeSteps(
  slugOrChallengeId: string,
  flowAPI: ControllerFlowAPI,
): Promise<ChallengeStep[]> {
  const mockedChallenge = getMockedChallenge(slugOrChallengeId, flowAPI);

  if (!slugOrChallengeId) {
    return null;
  } else if (mockedChallenge) {
    return [];
  }

  return (
    await request(flowAPI, listSteps, {
      challengeId: slugOrChallengeId,
      fromOffset: {
        unit: DurationUnit.DAYS,
        value: 0,
      },
      toOffset: {
        unit: DurationUnit.DAYS,
        value: 1000,
      },
    })
  )?.data?.steps;
}
