import { GetGroupResponse } from '@wix/ambassador-social-groups-v2-group/types';
import { getGroup } from '@wix/ambassador-social-groups-v2-group/http';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ISocialGroupsContext } from './interfaces';

export const socialGroupsPropsMap = function (
  flowAPI: ControllerFlowAPI,
): ISocialGroupsContext {
  return {
    requestSocialGroup: async (groupId) => {
      if (!groupId) {
        return;
      }

      let groupRes: { data: GetGroupResponse };

      try {
        groupRes = await flowAPI.httpClient.request(getGroup({ groupId }));

        flowAPI.controllerConfig.setProps({
          socialGroup: groupRes.data.group,
        });
      } catch (error) {}
    },
    socialGroup: null,
  };
};
