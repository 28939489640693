import { ActionTypes, ScreenNames } from '../../main/biInterfaces';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IUserContext } from '../UserContext';
import { paidPlansPropsMap } from '../../PaidPlans/paidPlansPropsMap';
import { getChallengeId } from '../../ChallengeDataProvider/challengeDataProviderPropsMap';
import { biBeforeAndAfter, biChangePage } from '../../../services/biHelpers';
import { deleteParticipant } from '@wix/ambassador-challenges-v1-participant/http';
import { request } from '../../../services/request';

export async function leaveTheChallenge(
  flowAPI: ControllerFlowAPI,
  participantId: string,
  userProvider: IUserContext,
  challengeId?: string,
) {
  await biBeforeAndAfter(
    flowAPI.bi,
    ActionTypes.LEAVE_THE_CHALLENGE,
    async (actionId) => {
      return (
        await request(flowAPI, deleteParticipant, {
          actionId,
          challengeId: challengeId || (await getChallengeId(flowAPI)),
          participantId,
        })
      )?.data;
    },
  );

  await biChangePage(flowAPI.bi, ScreenNames.ChallengePage);

  await userProvider.updateParticipant();

  // because of PPs doesn't load when member is joined we need to trigger load of PPs on leave challenge
  await paidPlansPropsMap(flowAPI);

  flowAPI.controllerConfig.setProps({
    participantSteps: [],
  });
}
