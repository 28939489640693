export enum SelectedPaymentOption {
  SinglePayment = 'SinglePayment',
  PaidPlans = 'PaidPlans',
}

export interface IPricingProps {
  className?: string;
  disabled?: boolean;
  disabledEligible?: boolean;
  selectedPaymentOption: SelectedPaymentOption | null;
  onPaymentSelected(selectedPaymentOption: SelectedPaymentOption): void;
}
