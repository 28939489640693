import { benefitsList } from '../api/benefits/benefitsList.api';
import { handleError } from '../contexts/ErrorHandler/errorHandlerPropsMap';
import {
  BenefitWithPlanInfo,
  PlanStatus,
} from '@wix/ambassador-pricing-plan-benefits-server/types';
import { IHttpClient } from '@wix/yoshi-flow-editor';
import { listPublicPlans } from '@wix/ambassador-pricing-plans-v2-plan/http';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';

const PAID_PLANS_LIMIT = 100;

async function recursiveLoadPP(
  httpClient: IHttpClient,
  loadedPP: PublicPlan[],
  offset: number = 0,
): Promise<PublicPlan[]> {
  const ppRes = await httpClient.request(
    listPublicPlans({
      limit: PAID_PLANS_LIMIT,
      offset: offset * PAID_PLANS_LIMIT,
    }),
  );

  const plans = ppRes.data.plans;

  const acc = [...loadedPP, ...plans];

  if (plans.length === PAID_PLANS_LIMIT) {
    await recursiveLoadPP(httpClient, acc, ++offset);
  }

  return acc;
}

export const loadUserPaidPlans = async (httpClient: IHttpClient) => {
  try {
    const plans = await recursiveLoadPP(httpClient, []);
    let paidPlansWithBenefits: BenefitWithPlanInfo[] = [];

    paidPlansWithBenefits = (await httpClient.request(benefitsList()))?.data
      ?.benefits;

    const paidPlansWithBenefitsActive = paidPlansWithBenefits?.filter(
      ({ planInfo }) => planInfo.status === PlanStatus.ACTIVE,
    );

    return paidPlansWithBenefitsActive.length
      ? paidPlansWithBenefitsActive.map(({ planInfo, benefit }) => {
          const plan = plans.find(({ id }) => {
            return id === planInfo.id;
          });

          return {
            id: planInfo.id,
            details: {
              id: planInfo.id,
              name: planInfo.name,
              description: plan?.description || '',
            },
            challenges: benefit.resourceIds,
            paymentOptions: {
              price: {
                amount: plan?.pricing?.price?.value,
                currency: plan?.pricing?.price?.currency,
              },
              recurring: !!plan?.pricing.subscription,
              validFor: {
                forever: false,
                period: {
                  amount: plan?.pricing?.subscription?.cycleDuration?.count,
                  unit: plan?.pricing?.subscription?.cycleDuration?.unit,
                },
              },
            },
            visible: planInfo.visible || false,
          };
        })
      : [];
  } catch (error) {
    handleError({ error, context: 'loadUserPaidPlans' });
  }
};
