import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks';
import { IParticipantStepsContext } from './ParticipantStepsContext';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import { syncInstance } from '../../services/instance';
import userTypeHandlers from '../User/helpers/userTypeHandlers';
import {
  getChallengeWeekRangeForDate,
  getFirstDayOfChallenge,
} from '../../selectors/dates';
import {
  challengeDataProviderPropsMap,
  getChallengeId,
} from '../ChallengeDataProvider/challengeDataProviderPropsMap';

import format from 'date-fns/format';
import add from 'date-fns/add';
import addDays from 'date-fns/addDays';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';
import { isForcedPreviewParticipant } from '../../selectors/isForcedPreview';
import { challengesListDataProviderPropsMap } from '../ChallengesListDataProvider/challengesListDataProviderPropsMap';
import { isMockedChallenge } from '../main/getMockedChallenges';
import { toParticipantSteps } from './toParticipantSteps';
import { PARTICIPANT_STEPS } from '../../__mocks__/participantSteps';
import { getUrlParams } from '../Location/locationProviderPropsMap';
import { requestChallenge } from '../ChallengeDataProvider/helpers';
import { request } from '../../services/request';

import {
  Participant,
  ParticipantSection,
  State,
  ParticipantStep,
  ParticipantStepStateTransition,
  ListParticipantStepsResponse,
  DurationUnit,
  Feedback,
} from '@wix/ambassador-challenges-v1-participant/types';
import { listSteps as listStepsChallenge } from '@wix/ambassador-challenges-v1-challenge/http';
import {
  myProgramStep,
  listSteps,
} from '@wix/ambassador-challenges-v1-participant/http';

export async function handleUserLogin(flowAPI: ControllerFlowAPI) {
  flowAPI.controllerConfig.setProps({
    participantSteps: await loadParticipantSteps(flowAPI),
  });
}

function getOffset(
  selectedDate: Date,
  includeAll: boolean,
): { from: number; to: number } {
  if (includeAll) {
    return {
      from: 0,
      to: 365,
    };
  }
  if (!selectedDate) {
    return { from: 0, to: 6 };
  }

  const weekCount = differenceInCalendarWeeks(selectedDate, new Date());

  if (!weekCount) {
    return { from: 0, to: 6 };
  }

  return {
    from: weekCount * 6 + weekCount,
    to: weekCount * 7 + 6,
  };
}

export const loadParticipantSteps = async (
  flowAPI: ControllerFlowAPI,
  currentDate: Date = null,
  includeAll: boolean = false,
): Promise<IParticipantStepsContext['participantSteps']> => {
  const { isEditor, isPreview } = flowAPI.environment;
  const userProvider = await userProviderPropsMap(flowAPI);
  const { participant }: { participant?: Participant } = userProvider;

  syncInstance(flowAPI);

  const isJoinedParticipant =
    participant?.id &&
    userTypeHandlers.isJoinedAlready(participant?.transitions?.[0]?.state);

  let participantSteps: ListParticipantStepsResponse = {
    steps: [],
  };

  flowAPI.controllerConfig.setProps({
    isParticipantStepsLoading: true,
  });

  if (isJoinedParticipant && userProvider.userType !== State.RUNNING) {
    const weekRange = getChallengeWeekRangeForDate(
      participant.dateFrame,
      currentDate ? new Date(currentDate) : new Date(),
      flowAPI?.reportError,
    );

    try {
      const { challengeData } = await challengeDataProviderPropsMap(flowAPI);
      const challengeId = challengeData.challenge.id;

      if (
        !challengeId ||
        challengeData.challenge?.stepsSummary?.stepsNumber === 0
      ) {
        return;
      }

      participantSteps = (
        await request(flowAPI, listSteps, {
          challengeId,
          dateInterval: {
            start: includeAll
              ? format(
                  getFirstDayOfChallenge(participant.dateFrame),
                  'yyyy-MM-dd',
                )
              : weekRange.from
              ? format(weekRange.from, 'yyyy-MM-dd')
              : null,
            finish: includeAll
              ? `${format(
                  add(new Date(), {
                    years: 2,
                  }),
                  'yyyy',
                )}-01-01` // todo: remove after backend fix
              : weekRange.to
              ? format(addDays(weekRange.to, 1), 'yyyy-MM-dd')
              : null,
          },
          participantId: participant.id,
        })
      )?.data;
    } catch (error) {
      handleError({ error, context: 'participantAPI.listSteps' });
    }
  }

  if (
    (isEditor ||
      isPreview ||
      isForcedPreviewParticipant(
        flowAPI?.controllerConfig?.wixCodeApi?.location?.query,
      )) &&
    !participantSteps?.steps?.length
  ) {
    const challengeId =
      (await getChallengeId(flowAPI)) ||
      (await challengesListDataProviderPropsMap(flowAPI)).challengesListData
        ?.memberChallenges?.[0]?.challenge?.id;

    // for editor preview need to calculate correct range in scheduled challenge
    const offset = getOffset(currentDate, includeAll);

    let steps;

    if (challengeId && !isMockedChallenge(challengeId, flowAPI)) {
      try {
        const challengeSteps = (
          await request(flowAPI, listStepsChallenge, {
            challengeId,
            fromOffset: { value: offset.from, unit: DurationUnit.DAYS },
            toOffset: { value: offset.to, unit: DurationUnit.DAYS },
          })
        )?.data;

        steps = toParticipantSteps({ ownerSteps: challengeSteps });
      } catch (error) {
        handleError({ error, context: 'getOwnerListSteps' });
      }
    }

    participantSteps = {
      steps: steps || PARTICIPANT_STEPS,
    };
  }

  flowAPI.controllerConfig.setProps({
    isParticipantStepsLoading: false,
  });

  return participantSteps;
};
export const updateParticipantStepStatus = (
  flowAPI: ControllerFlowAPI,
  sections: ParticipantSection[],
  steps: ParticipantStep[],
  stepId: string,
  transitions?: ParticipantStepStateTransition[],
  feedback?: Feedback,
): void => {
  const checkStep = (step) => {
    if (step.id === stepId) {
      if (transitions) {
        step.transitions = transitions;
      }

      if (feedback?.items || feedback?.quiz) {
        step.feedback = feedback;
      }
    }
  };

  if (sections?.length) {
    sections.forEach((section) => {
      (section.steps || []).forEach(checkStep);
    });

    flowAPI.controllerConfig.setProps({
      listParticipantSections: sections,
    });
  } else if (steps?.length) {
    steps.forEach(checkStep);

    flowAPI.controllerConfig.setProps({
      participantSteps: { steps },
    });
  } else {
    console.error("Can't update participant step status.");
  }
};

export async function getStepNavigationInfo(flowAPI): Promise<{
  selectedStep?: ParticipantStep;
  selectedDate?: Date;
}> {
  const { navigationType, slug, navigationId } = getUrlParams(flowAPI);
  if (navigationType === 'step') {
    const response = await requestChallenge(slug, flowAPI);
    const selectedStep = (
      await request(flowAPI, myProgramStep, {
        programStepId: navigationId,
        programId: response.challenge.id,
      })
    )?.data?.participantStep;
    const selectedDate = selectedStep?.dateFrame?.start
      ? new Date(selectedStep?.dateFrame?.start)
      : undefined;
    return { selectedStep, selectedDate };
  }
  return {
    selectedStep: undefined,
    selectedDate: undefined,
  };
}
