import isEmpty from 'lodash/isEmpty';
import { APPS } from '../config/constants';
import { Pages } from '../contexts/Location/LocationContext';

export const PAID_PLANS_APP_ID = '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3';

export function getPaidPlansSectionUrl(wixCodeApi) {
  try {
    return (
      wixCodeApi.site
        .getSiteStructure()
        .pages.find((page) => page.applicationId === PAID_PLANS_APP_ID).url ||
      ''
    );
  } catch (e) {
    console.error('[challenges]Paid plans page is not installed');
    return '';
  }
}

export const navigateToPaidPlans = ({
  planIds,
  url,
  titleText,
  buttonText,
  contentText,
  pageId,
  wixCodeApi,
}: {
  url: string;
  planIds: string[];
  titleText: string;
  buttonText: string;
  contentText: string;
  pageId: Pages;
  wixCodeApi: any;
}) => {
  const queryParams: any = {
    planIds: planIds.join(','),
    // navigateToPageProps: btoa(pageId),
    navigateToSectionProps: btoa(
      JSON.stringify({
        sectionId: pageId,
        appDefinitionId: APPS.Prod,
        state: url,
        shouldRefreshIframe: true,
      }),
    ),
  };
  const content: any = { titleText, buttonText, contentText };

  try {
    queryParams.verticalStatusContent = btoa(JSON.stringify(content)); // this throws with base-64 chars (japan language for example)
  } catch (error) {
    console.error(error);
  }

  let path = '';
  if (!isEmpty(queryParams)) {
    console.log(JSON.stringify(queryParams));
    path = `?appSectionParams=${encodeURIComponent(
      JSON.stringify(queryParams),
    )}`;
  }

  const sectionUrl = getPaidPlansSectionUrl(wixCodeApi);

  return wixCodeApi.location.to(`${sectionUrl}${path}`);
};
