import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IParticipantSectionsContext } from './ParticipantSectionsContext';
import { onParticipantLogin } from '../User/userProviderPropsMap';
import flatten from 'lodash/flatten';
import { getSectionNavigationInfo, loadParticipantSections } from './helpers';

const handleParticipantLogin = async (flowAPI: ControllerFlowAPI) => {
  flowAPI.controllerConfig.setProps({
    sections: await loadParticipantSections(flowAPI),
  });
};

export const participantSectionsPropsMap = async function (
  flowAPI: ControllerFlowAPI,
): Promise<IParticipantSectionsContext> {
  onParticipantLogin(handleParticipantLogin);

  const selectedSection = await getSectionNavigationInfo(flowAPI);
  const sections = await loadParticipantSections(flowAPI);
  const steps = flatten(sections.map((section) => section.steps || []));

  return {
    selectedSection,
    isListParticipantSectionsRequestInProgress: false,
    listParticipantSections: sections,
    participantSteps: { steps },
    updateParticipantSections: async () => {
      const _sections = await loadParticipantSections(flowAPI);
      const _steps = flatten(sections.map((section) => section.steps || []));

      flowAPI.controllerConfig.setProps({
        listParticipantSections: _sections,
        participantSteps: { steps: _steps },
      });
    },
  };
};
