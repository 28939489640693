import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IParticipantStepsContext } from './ParticipantStepsContext';
import { onParticipantLogin } from '../User/userProviderPropsMap';
import {
  getStepNavigationInfo,
  handleUserLogin,
  loadParticipantSteps,
  updateParticipantStepStatus,
} from './helpers';

export const participantStepsDataProviderPropsMap = async function (
  flowAPI: ControllerFlowAPI,
): Promise<IParticipantStepsContext> {
  onParticipantLogin(handleUserLogin);
  const { selectedStep, selectedDate } = await getStepNavigationInfo(flowAPI);

  const isSidebarLayout =
    (flowAPI.controllerConfig.config?.publicData as any)?.COMPONENT
      ?.participantLayout === 'SIDEBAR';

  return {
    selectedStep,
    selectedDate: selectedDate?.valueOf() as any,
    participantSteps: await loadParticipantSteps(
      flowAPI,
      selectedDate,
      isSidebarLayout,
    ),
    updateParticipantSteps: async (
      currentDate: Date = null,
      includeAll: boolean = false,
    ) => {
      flowAPI.controllerConfig.setProps({
        participantSteps: await loadParticipantSteps(
          flowAPI,
          currentDate,
          includeAll || isSidebarLayout, // we need always load all steps for sidebar layout until we add paging
        ),
      });
    },
    isParticipantStepsLoading: false,
    updateParticipantStepStatus: async (
      sections,
      steps,
      stepId,
      transitions?,
      feedback?,
    ) =>
      updateParticipantStepStatus(
        flowAPI,
        sections,
        steps,
        stepId,
        transitions,
        feedback,
      ),
  };
};
