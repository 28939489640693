import { query } from '@wix/ambassador-badges-v2-badge/http';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IBadgesContext } from './interfaces';
import { request } from '../../services/request';

export const badgesProviderPropsMap = async function (
  flowAPI: ControllerFlowAPI,
): Promise<IBadgesContext> {
  return {
    requestBadges: async (badgeIds) => {
      const badgeRes = await request(flowAPI, query, {
        filter: {
          _id: {
            $in: badgeIds,
          },
        },
      });

      flowAPI.controllerConfig.setProps({
        badges: badgeRes.data?.badges,
      });
    },
    badges: [],
  };
};
