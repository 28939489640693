import memoize from 'lodash/memoize';

import { listCategories } from '@wix/ambassador-challenges-v1-category/http';
import { ControllerFlowAPI, HttpClient } from '@wix/yoshi-flow-editor';
import { categories as categoriesMethod } from '../../api/categories/categories.api';

import { CategoriesDataProps } from './CategoriesDataProvider';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';
import { request } from '../../services/request';

export const shouldReportErrorMonitor = (err) => err?.httpStatus !== 403;

async function getCategoriesServerless() {
  return (await new HttpClient().request(categoriesMethod()))?.data;
}

export async function getCategoriesHttp(flowAPI: ControllerFlowAPI) {
  let categories = [];

  try {
    categories =
      (await request(flowAPI, listCategories))?.data?.categories || [];
  } catch (error) {
    handleError({
      error,
      context: 'GetCategoriesList',
      preventErrorMonitorReport: !shouldReportErrorMonitor(error),
    });
  }

  return { categories };
}

async function handleUserLogin(flowAPI: ControllerFlowAPI) {
  flowAPI.controllerConfig.wixCodeApi.user.onLogin(async (user) => {
    let categories;

    try {
      categories = await getCategoriesHttp(flowAPI);
    } catch (error) {
      handleError({
        error,
        context: 'onLogin.getCategories',
      });
    }

    flowAPI.controllerConfig.setProps({
      categoriesData: {
        ...categories,
      },
    });
  });
}

export const categoriesDataProviderPropsMap = memoize(async function (
  flowAPI: ControllerFlowAPI,
): Promise<CategoriesDataProps> {
  let categories;

  await handleUserLogin(flowAPI);

  try {
    categories = await getCategoriesHttp(flowAPI);
  } catch (error) {
    console.error(error);
    handleError({ error, context: 'getCategories' });
  }

  // dont think we need it now
  // if (!isMA(flowAPI) && !isViewer && !categories) {
  // categories = getMockedCategories();
  // }

  return {
    categoriesData: {
      ...categories,
    },
  };
});
