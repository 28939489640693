import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IPaidPlansProviderProps } from './interfaces';
import { loadUserPaidPlans } from '../../services/loadPaidPlans';
import { getUserEligiblePlans } from './helpers/getUserEligiblePlans';
import { MEMBERSHIP_APP_ID, PP_SECTION_ID } from '../../editor/app-config';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import { userTypeHandlers } from '../User/helpers/userTypeHandlers';

let paidPlans = null;

export async function paidPlansPropsMap(
  flowAPI: ControllerFlowAPI,
): Promise<IPaidPlansProviderProps> {
  if (paidPlans) {
    return paidPlans;
  }

  const userType = (await userProviderPropsMap(flowAPI)).userType;
  const isParticipant = userTypeHandlers.isJoinedAlready(userType);

  if (isParticipant) {
    return {
      userPaidPlans: [],
      eligiblePlans: [],
    };
  }

  const isPPInstalled = await flowAPI.controllerConfig.wixCodeApi.site.isAppSectionInstalled(
    {
      appDefinitionId: MEMBERSHIP_APP_ID,
      sectionId: PP_SECTION_ID,
    },
  );

  const userPaidPlans = isPPInstalled
    ? await loadUserPaidPlans(flowAPI.httpClient)
    : [];

  if (userPaidPlans) {
    flowAPI.controllerConfig.wixCodeApi.user.onLogin(() => {
      paidPlans.updatePromise = (async () => {
        paidPlans.userPaidPlans = isPPInstalled
          ? await loadUserPaidPlans(flowAPI.httpClient)
          : [];
        paidPlans.eligiblePlans = isPPInstalled
          ? await getUserEligiblePlans(flowAPI)
          : [];
        flowAPI.controllerConfig.setProps(paidPlans);
      })();
    });
  }

  paidPlans = {
    userPaidPlans,
    eligiblePlans: isPPInstalled ? await getUserEligiblePlans(flowAPI) : [],
    updatePromise: Promise.resolve(),
  };

  flowAPI.controllerConfig.setProps({
    userPaidPlans: paidPlans.userPaidPlans,
    eligiblePlans: paidPlans.eligiblePlans,
  });

  return paidPlans;
}
