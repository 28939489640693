import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { userProviderPropsMap } from '../../User/userProviderPropsMap';
import {
  GetEligibleOrdersResponse,
  MemberOrdersByPlan,
  PlanStatus,
} from '@wix/ambassador-pricing-plan-benefits-server/types';
import { UserState } from '../../User/UserContext';
import { handleError } from '../../ErrorHandler/errorHandlerPropsMap';
import { getChallengeId } from '../../ChallengeDataProvider/challengeDataProviderPropsMap';
import { isMockedChallenge } from '../../main/getMockedChallenges';
import { eligibleOrders } from '../../../api/benefits/eligibleOrders.api';

const filterActivePlan = ({ planInfo }: any) =>
  planInfo.status === PlanStatus.ACTIVE;

export async function getUserEligiblePlans(
  flowAPI: ControllerFlowAPI,
  challengeId?: string,
): Promise<MemberOrdersByPlan[]> {
  const user = await userProviderPropsMap(flowAPI);
  challengeId = challengeId ? challengeId : await getChallengeId(flowAPI);

  if (
    !challengeId ||
    user.userType === UserState.VISITOR ||
    isMockedChallenge(challengeId, flowAPI)
  ) {
    return [];
  }

  try {
    let orders: GetEligibleOrdersResponse = {};

    orders = (
      await flowAPI.httpClient.request(
        eligibleOrders(user.user.id, challengeId, new Date().toISOString()),
      )
    )?.data?.orders;

    return [
      ...orders.memberOrdersByPlan?.filter(filterActivePlan),
      ...orders.memberFutureOrdersByPlan?.filter(filterActivePlan),
    ]
      .filter((item) => !!item)
      .map((eligPlan) => {
        if (eligPlan.memberOrders?.length) {
          eligPlan.memberOrders.forEach((order) => {
            if (order.validFrom && typeof order.validFrom === 'string') {
              /*
              We work with validFrom in context of date, not date+time.
              So some operation can work incorrectly with specific time, and because of it in this place time is replaced to first second of day.
              */
              order.validFrom = order.validFrom.replace(
                /T.*?Z/,
                'T00:00:01.000Z',
              );
            }

            return order;
          });
        }

        return eligPlan;
      });
  } catch (error) {
    handleError({
      context: 'getEligibleOrders',
      error,
    });
    return [];
  }
}
