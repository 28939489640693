import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import pickBy from 'lodash/pickBy';
import { IGoToPageOptions, PageSlugs } from './LocationContext';
import { ILocation } from '@wix/native-components-infra/dist/src/types/types';

interface IAppendQueryToUrl {
  query?: string;
  url: string;
}

export function getSitePath(baseUrl: string) {
  const basePathIndex = 3;
  const path = baseUrl
    .split('?')[0]
    .split('/')
    .slice(0, basePathIndex + 1);
  if (baseUrl.includes('wix.com')) {
    return baseUrl.split('wix.com')[1] || '/';
  }
  if (!path[basePathIndex]) {
    return '/';
  }

  return `/${path[basePathIndex]}`;
}

export function getSiteOrigin(baseUrl: string) {
  if (!baseUrl) {
    return '';
  }

  return baseUrl.split('?')[0].split('/').slice(0, 3).join('/');
}

const getQueryParamObject = (flowAPI: ControllerFlowAPI) =>
  flowAPI?.controllerConfig?.wixCodeApi?.location?.query || {};

export const getQueryParamsString = (
  flowAPI: ControllerFlowAPI,
  allowedProps: string[] = Object.keys(getQueryParamObject(flowAPI)), // if empty array will be generated link with all query parameters
): string => {
  const urlQueryParametersObject = getQueryParamObject(flowAPI);

  return allowedProps.reduce((acc, propKey) => {
    const propValue = urlQueryParametersObject[propKey];

    if (propKey in urlQueryParametersObject) {
      if (!propValue) {
        return acc ? `${acc}&${propKey}` : `${propKey}`;
      }
      return acc ? `${acc}&${propKey}=${propValue}` : `${propKey}=${propValue}`;
    }
    return acc;
  }, '');
};

export const generateQueryString = (obj = {}) => {
  return Object.entries(obj)
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join('&');
};

export const appendQueryStringToUrl = ({ query, url }: IAppendQueryToUrl) => {
  if (!query) {
    return url;
  }
  if (url.includes('?')) {
    return `${url}&${query}`;
  }
  return `${url}?${query}`;
};

export const withAllQueryParameters = (
  url: string,
  flowAPI: ControllerFlowAPI,
) => {
  return appendQueryStringToUrl({ query: getQueryParamsString(flowAPI), url });
};

// used for developing to automatically pass overwritesQuery from page to page
export const withDevQueryParams = (
  url: string,
  flowAPI: ControllerFlowAPI,
): string => {
  const devProps = [
    'forceThunderbolt',
    'ssrDebug=true',
    'ssrWarmupOnly',
    'shareTitle',
    'petri_ovr',
    'fbclid',
    'forcedPreviewAs',
    'instance',
    ...Object.keys(getDevOverwrites(flowAPI).overrides || {}),
  ];

  return appendQueryStringToUrl({
    query: getQueryParamsString(flowAPI, devProps),
    url,
  });
};

export const getDevOverwrites = (flowAPI: ControllerFlowAPI) => {
  const { query } = flowAPI.controllerConfig.wixCodeApi.location;

  const isApplyOverrides =
    Object.keys(query).includes('editorScriptUrlOverride') ||
    Object.keys(query).includes('tpaWidgetUrlOverride');
  const overrides = isApplyOverrides
    ? pickBy(flowAPI.controllerConfig.wixCodeApi.location.query, (value, key) =>
        key.toLowerCase().includes('override'),
      )
    : null;
  return { isApplyOverrides, overrides };
};

export const getTargetUrl = async (
  options: IGoToPageOptions,
  flowAPI: ControllerFlowAPI,
) => {
  const isAbsoluteURL = !!options.isAbsoluteURL;
  const { appDefinitionId } = flowAPI.environment;

  const { url, relativeUrl } =
    await flowAPI.controllerConfig.wixCodeApi.site.getSectionUrl({
      sectionId: options.pageId,
      appDefinitionId,
    });
  const baseUrl = isAbsoluteURL ? url : relativeUrl;
  const resultUrl = options.challengeId
    ? `${baseUrl}/${options.challengeId}`
    : baseUrl;
  return withDevQueryParams(resultUrl, flowAPI);
};

export function getPageUrlInfo(location: Pick<ILocation, 'path' | 'baseUrl'>): {
  pagePath: string;
  routerPath: string;
  basePath: string;
} {
  const pagePath = [];
  const routerPath = [];
  let routerPathStarted = false;
  location.path
    .filter((segment) => !!segment)
    .forEach((segment, i, array) => {
      if (routerPathStarted) {
        routerPath.push(segment);
      } else {
        pagePath.push(segment);
      }
      if (array[i - 1] === PageSlugs.challenge_page) {
        routerPathStarted = true;
      }
    });
  const pagePathString = `/${pagePath.join('/')}`;
  const basePath = `${getSitePath(location.baseUrl)}${pagePathString}`?.replace(
    /\/\//gi,
    '/',
  );
  return {
    pagePath: pagePathString,
    basePath,
    routerPath: `/${routerPath.join('/')}`,
  };
}
